import * as React from "react"

import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

import Layout from "../components/structural/layout"
import OTLink from "../components/global/otLink"
import { graphql } from "gatsby"

const NotFoundPage = props => {
  const { data } = props
  let story = data?.storyblokEntry
  story = useStoryblokState(story)

  return (
    <Layout>
      <div
        {...storyblokEditable(story?.content)}
        className="section-container mx-auto mt-20"
      >
        <h1 className="mb-12">
          {story?.content?.four_oh_four_title || "404: Not Found"}
        </h1>
        <p className="text-desktop-body mb-8">
          {story?.content?.four_oh_four_description ||
            "You just hit a route that doesn't exist"}
        </p>
        <OTLink icon={true} className="mt-20" to="/">
          {story?.content?.four_oh_four_button_text || "Home Page"}
        </OTLink>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const QUERY = graphql`
  query FourOhFourDefaultQuery {
    storyblokEntry(full_slug: { eq: "site-settings" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
